import "../CSS/Home.css";
import personalImg from "../images/headshot2.jpg.jpeg";
import resume from "../images/Resume - Caleb Holland.pdf";


import { TypeAnimation } from "react-type-animation";
export default function Home() {
  return (
    <section id="home" class="About-page">
      <div className="about-sub">
        <div>
          <div class="header2">
            <h1 className="text-5xl font-bold bio mb-2">Hi, I'm Caleb!</h1>
            <div className="lg:text-3xl sm:text-sm mb-2">
              <TypeAnimation
                sequence={[
                  "Incoming Data Engineer Intern @ BlackRock",
                  2000,
                  "Computer Science Student @ UMD",
                  2000,
                  "Former SWE Intern @ PNC",
                  2000,
                  "Webmaster @ Kingdom of Wonders",
                  2000,
                ]}
                speed={50}
                repeat={Infinity}
              />
            </div>
            <hr class="sm:w-1/2 w-full"></hr>
          </div>

          <p className="bio">
            <br></br>
            I'm a junior studying computer science and astronomy at the
            University of Maryland, College Park.
            <br></br>
            <br></br>
            This past summer, I interned at PNC Financial Services as a Software
            Engineer Intern on the Quality Metrics Reporting team. This coming
            summer, I’ll be working as a summer analyst at BlackRock on the
            Aladdin Data Engineering team. I’m excited to get started, and can’t
            wait to learn.
            <br></br>
            <br></br>
            In my free time, I run marathons and bake! I dream to open my very
            own bakery one day. Whenever I’m feeling silly, I work on theming
            some of my development tools around a character from one of my
            favorite childhood shows, Applejack!
            <br></br>
            <br></br>
            Determination, creativity, and weirdness are what make me who I am.
          </p>
          <div className="buttons mt-10 mb-10">
            <button className="butn mr-10">
              <a
                href="mailto:hollandcw3@gmail.com"
              >
                Contact
              </a>
            </button>
            <a href={resume} download>
              <button className="butn">Resume</button>
            </a>
          </div>
        </div>
        <img
          className="image"
          src={personalImg}
          alt="caleb holland"
          width="400px"
        />
      </div>
    </section>
  );
}
